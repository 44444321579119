import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchNews = createAsyncThunk(
  "news/admin/fetchNews",
  async ({ page, limit, type, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/news/get`,
        {
          params: { page, limit, type, search },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching news");
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: {
    list: [],
    total: 0,
    page: 1,
    totalPages: 1,
    filterNews: "all",
    searchQuery: "",
    status: "idle",
    loading: false,
    error: null,
  },
  reducers: {
    setFilterNews: (state, action) => {
      state.filterNews = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    deleteNewsItem: (state, action) => {
      const newsItem = state.list.find((news) => news._id === action.payload);
      if (newsItem) {
        newsItem.deleted = true;
      }
    },
    updateNewsStatus: (state, action) => {
      const { id, status } = action.payload;
      const newsItem = state.list.find((news) => news._id === id);
      if (newsItem) {
        newsItem.status = status;
      }
    },
    updateNewsVisibility: (state, action) => {
      const { id, visibility } = action.payload;
      const newsItem = state.list.find((news) => news._id === id);
      if (newsItem) {
        newsItem.contentFor = visibility;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.list = action.payload.news.map((news) => ({
          ...news,
          deleted: false,
        }));
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setFilterNews,
  setPage,
  setSearchQuery,
  deleteNewsItem,
  updateNewsStatus,
  updateNewsVisibility,
} = newsSlice.actions;

export default newsSlice.reducer;
