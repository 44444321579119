import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { formateCoinPrice } from '../../utils/common/formateNumber';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import ChartComponent from '../customer/DemoChart';

function CoinHoverCard({ hoverPosition, hoverData }) {
    const [coin, setCoin]=useState(null);
  const styles = {
    hoverCard: {
      position: 'absolute',
      backgroundColor: 'white',
      border: '1px solid #ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      maxWidth:"20rem",
      left: `${hoverPosition.x}px`,
      top: `${hoverPosition.y}px`,
      alignItem:'start',
    },
  };

 
  useEffect(() => {
    const fetchCoin = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/global-data/getCoins/${hoverData}`
        );
        setCoin(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCoin();
  }, [hoverData]);

  return (
    <div style={styles.hoverCard}>
       <Row>
       <Col xs={4}>
  <Image src={coin?.img.url} style={{ width: "100%", height: "auto" }} />
</Col>
        <Col xs={6}>
        <strong>{coin?.name}</strong> {coin?.symbol}
        </Col>
        <Col xs={2}>
        #{coin?.rank}
        </Col>
       </Row>
     <Row>
      <div className="d-flex align-items-center gap-2 my-2">
                     <strong>
                       ${formateCoinPrice(coin?.quotes?.USD?.price)}
                     </strong>
                     <span
                       className={
                         coin?.quotes?.USD?.percent_change_24h > 0
                           ? "text-success"
                           : "text-danger"
                       }
                     >
                       {coin?.quotes?.USD?.percent_change_24h > 0 ? (
                         <FaCaretUp />
                       ) : (
                         <FaCaretDown />
                       )}
                       {Math.abs(coin?.quotes?.USD?.percent_change_24h).toFixed(1)}
                       %
                     </span>
                   </div>
     </Row>
     <div>
        <ChartComponent data={coin?.quotes.USD} showVolume={true}/>
     </div>
    </div>
  );
}

export default CoinHoverCard;
