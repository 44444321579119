import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FiLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import { flashAction } from "../../../../store/customer/flashSlice";
import { useDispatch } from "react-redux";

function ShareFeedRepostModal({
  showShare,
  setShowShare,
  repost = null,
  feed = null,
}) {
  const dispatch = useDispatch();
  const shareUrl = `${process.env.REACT_APP_MAIN_ORIGIN}/community/${
    feed ? "feed" : "repost"
  }/${feed ? feed._id : repost._id}`;

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        dispatch(
          flashAction.addFlash({
            message: "Link copied to clipboard!",
            type: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          flashAction.addFlash({
            message: "Failed to copy the link. Please try again.",
            type: "Fail",
          })
        );
      });
  };

  return (
    <>
      <Modal show={showShare} onHide={() => setShowShare(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fw-semibold text-center w-100">
            Share
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            Share <strong>{repost?.repostedBy?.name}'s</strong> post:
          </p>
          <div className="d-flex flex-wrap justify-content-center gap-3 mt-3">
            <Link
              to={`https://X.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
                `${(feed ? feed?.post : repost?.caption)
                  ?.split(" ")
                  .slice(0, 30)
                  .join(" ")}... \n ${process.env.REACT_APP_TWITTER_HANDLE}\n\n`
              )}`}
              target="_blank"
            >
              <XIcon size={40} round={true} />
            </Link>
            <Link
              to={`https://telegram.me/share/url?url=${encodeURIComponent(
                shareUrl
              )}&text=${encodeURIComponent(
                `**${
                  feed ? feed?.createdBy?.name : repost?.repostedBy?.name
                }** \n \n \n ${feed ? feed?.post : repost.caption}`
              )}`}
              target="_blank"
            >
              <TelegramIcon size={40} round={true} />
            </Link>
            <Link
              to={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                `\n \n${feed ? feed?.post : repost.caption}`
              )} \n ${shareUrl}`}
              target="_blank"
            >
              <WhatsappIcon size={40} round={true} />
            </Link>
            <Link
              to={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              target="_blank"
            >
              <FacebookIcon size={40} round={true} />
            </Link>
            <Link
              to={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                shareUrl
              )}&title=${encodeURIComponent(
                feed ? feed?.createdBy?.name : repost?.repostedBy?.name
              )}&summary=${encodeURIComponent(
                feed ? feed?.post : repost?.caption
              )}`}
              target="_blank"
            >
              <LinkedinIcon size={40} round={true} />
            </Link>
            <Link
              to={`https://reddit.com/submit?url=${shareUrl}&title=${encodeURIComponent(
                `${feed ? feed?.createdBy?.name : repost?.repostedBy?.name}`
              )}`}
              target="_blank"
            >
              <RedditIcon size={40} round={true} />
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3 bg-light">
          <div className="d-flex w-100">
            <input
              type="text"
              className="form-control me-2"
              value={shareUrl}
              readOnly
            />
            <Button
              variant="secondary"
              className="d-flex align-items-center"
              onClick={handleCopyLink}
            >
              <FiLink size={20} className="me-2" /> Copy
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShareFeedRepostModal;
