import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler, 
} from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Filler);

const ChartComponent = ({ data, showVolume=false }) => {
  const borderColor = data?.percent_change_7d >= 0 ? "green" : "red";
  const backgroundColor = data?.percent_change_7d >= 0 ? "rgba(0, 255, 0, 0.3)" : "rgba(255, 0, 0, 0.3)";

  // Main chart data when volume (area) is required
  const chartDataWithVolume = {
    labels: ["15m", "30m", "1h", "6h", "12h", "24h", "7d"],
    datasets: [
      {
        data: [
          data?.percent_change_15m,
          data?.percent_change_30m,
          data?.percent_change_1h,
          data?.percent_change_6h,
          data?.percent_change_12h,
          data?.percent_change_24h,
          data?.percent_change_7d,
        ],
        borderColor: borderColor,
        borderWidth: 1.5,
        tension: 0.4,
        pointRadius: 0,
        fill: false,
      },
      {
        data: [
          Math.max(data?.percent_change_15m, 0),
          Math.max(data?.percent_change_30m, 0),
          Math.max(data?.percent_change_1h, 0),
          Math.max(data?.percent_change_6h, 0),
          Math.max(data?.percent_change_12h, 0),
          Math.max(data?.percent_change_24h, 0),
          Math.max(data?.percent_change_7d, 0),
        ],
        borderColor: "transparent",
        backgroundColor: "rgba(0, 255, 0, 0.3)",
        fill: "origin",
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [
          Math.min(data?.percent_change_15m, 0),
          Math.min(data?.percent_change_30m, 0),
          Math.min(data?.percent_change_1h, 0),
          Math.min(data?.percent_change_6h, 0),
          Math.min(data?.percent_change_12h, 0),
          Math.min(data?.percent_change_24h, 0),
          Math.min(data?.percent_change_7d, 0),
        ],
        borderColor: "transparent",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
        fill: "origin",
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  // Main chart data when no volume (area) is required (fallback to original line graph)
  const chartDataWithoutVolume = {
    labels: ["15m", "30m", "1h", "6h", "12h", "24h", "7d"],
    datasets: [
      {
        data: [
          data?.percent_change_15m,
          data?.percent_change_30m,
          data?.percent_change_1h,
          data?.percent_change_6h,
          data?.percent_change_12h,
          data?.percent_change_24h,
          data?.percent_change_7d,
        ],
        borderColor: borderColor,
        borderWidth: 1.5,
        tension: 0.4,
        pointRadius: 0,
        fill: false, // No area fill
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <div style={{ height: "3rem", width: "100%" }}>
      {/* Conditional Rendering */}
      <Line data={showVolume ? chartDataWithVolume : chartDataWithoutVolume} options={chartOptions} />
    </div>
  );
};

export default ChartComponent;
