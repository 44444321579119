import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { flashAction } from "../../../../store/customer/flashSlice";
import { useDispatch } from "react-redux";

function DeleteFeedRepostModal({
  onDelete,
  feed = null,
  showDeleteModal,
  setShowDeleteModal,
  repost = null,
  detail,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteFeed = () => {
    try {
      let response;
      if (feed) {
        response = axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/community/delete-feed/${feed._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      } else {
        response = axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/community/delete-repost/${repost._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      }
      if (detail) {
        dispatch(
          flashAction.addFlash({ message: "navigate ho rha hai", type: "Fail" })
        );

        navigate("/community");
      } else {
        onDelete(feed ? feed?._id : repost._id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  return (
    <>
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this feed?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteFeed();
              setShowDeleteModal(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteFeedRepostModal;
