import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchNotification = createAsyncThunk(
  "notification/fetchNotification",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/${userId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch notifications");
    }
  }
);

export const sendNotification = createAsyncThunk(
  "notification/sendNotification",
  async (
    { title, message, token, link, userId, type, repliesBy },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/sendNotification`,
        {
          title,
          message,
          token,
          link,
          userId,
          repliesBy,
          type,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchToken = createAsyncThunk(
  "notification/fetchToken",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/getToken/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const sendNotificationToFollowers = createAsyncThunk(
  "notification/sendNotificationToFollowers",
  async (
    { title, message, link, image, type, userId },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/sendNotificationToFollowers`,
        { title, message, link, image, type, userId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const sendNotificationToAllUsers = createAsyncThunk(
  "notification/sendNotificationToAllUsers",
  async (
    { title, message, link, image, userId, type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/sendAllNotification`,
        {
          title,
          message,
          link,
          image,
          userId,
          type,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const markAsRead = createAsyncThunk(
  "notification/markAsRead",
  async ({ userId, notificationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/markAsRead`,
        { userId, notificationId }
      );
      return { notificationId, isRead: true };
    } catch (error) {
      return rejectWithValue("Failed to mark notification as read");
    }
  }
);

export const markAllAsRead = createAsyncThunk(
  "notification/markAllAsRead",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/markAllAsRead`,
        { userId }
      );
      return { isRead: true };
    } catch (error) {
      return rejectWithValue("Failed to mark all notifications as read");
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    loading: false,
    success: false,
    error: null,
    message: null,
    fcmToken: null,
  },
  reducers: {
    resetNotificationState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
      state.message = null;
      state.fcmToken = null;
    },
    clearNotifications: (state) => {
      state.notification = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(sendNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || "Failed to send notification";
      })
      .addCase(fetchToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.loading = false;
        state.fcmToken = action.payload;
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch FCM token";
      })
      .addCase(sendNotificationToFollowers.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(sendNotificationToFollowers.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(sendNotificationToFollowers.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error =
          action.payload || "Failed to send notification to followers";
      })

      .addCase(sendNotificationToAllUsers.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(sendNotificationToAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message;
        state.error = null;
      })
      .addCase(sendNotificationToAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error =
          action.payload || "Failed to send notifications to all users";
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notification = action.payload;
      })

      .addCase(markAsRead.fulfilled, (state, action) => {
        const { notificationId, isRead } = action.payload;
        state.notification = state.notification.map((notification) =>
          notification._id === notificationId
            ? { ...notification, isRead }
            : notification
        );
      })

      .addCase(markAllAsRead.fulfilled, (state) => {
        state.notification = state.notification.map((notification) => ({
          ...notification,
          isRead: true,
        }));
      });
  },
});

export const { resetNotificationState, clearNotifications } =
  notificationSlice.actions;
export const selectNotifications = (state) => state.notification.notification;
export default notificationSlice.reducer;
