import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchTopbarData = createAsyncThunk("new/global-data", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/global-data/topbar`
  );
  return response.data;
});

export const fetchNewCoinData = createAsyncThunk(
  "globalData/fetchNewCoinData",
  async ({ page = 1, limit = 25 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/global-data/newlisting`,
        {
          params: { page, limit },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch new coin data"
      );
    }
  }
);
const initialState = {
  topbarData: null,
  newCoinData: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  },
  newListingStatus: "idle",
  topbarStatus: "idle",
  error: null,
};

const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopbarData.pending, (state) => {
        state.topbarStatus = "loading";
      })
      .addCase(fetchTopbarData.fulfilled, (state, action) => {
        state.topbarStatus = "succeeded";
        state.topbarData = action.payload;
      })
      .addCase(fetchTopbarData.rejected, (state, action) => {
        state.topbarStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchNewCoinData.pending, (state) => {
        state.newListingStatus = "loading";
      })
      .addCase(fetchNewCoinData.fulfilled, (state, action) => {
        state.newListingStatus = "succeeded";
        state.newCoinData = action.payload.data;
        state.pagination = {
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalItems: action.payload.totalItems,
        };
      })
      .addCase(fetchNewCoinData.rejected, (state, action) => {
        state.newListingStatus = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export const selectTopbar = (state) => state.globalData.topbarData;
export const selectnewCoinData = (state) => state.globalData.newCoinData;
export const selectGlobalDataStatus = (state) => state.globalData.topbarStatus;
export const selectPagination = (state) => state.globalData.pagination;
export const selectNewListingStatus = (state) =>
  state.globalData.newListingStatus;

export default globalDataSlice.reducer;
