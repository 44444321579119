import { Canvas, useFrame } from "@react-three/fiber";
import { useState, useRef, useEffect } from "react";

function Coin({ position }) {
  const ref = useRef();
  return (
    <mesh ref={ref} position={position}>
      <sphereGeometry args={[0.5, 32, 32]} />
      <meshStandardMaterial color="gold" />
    </mesh>
  );
}

function MovingObject({ targetPosition, onCollide }) {
  const ref = useRef();

  // Smooth transition towards the coin position
  useFrame(() => {
    if (ref.current && targetPosition) {
      ref.current.position.lerp(
        { x: targetPosition[0], y: targetPosition[1], z: targetPosition[2] },
        0.02
      );

      const distance = ref.current.position.distanceTo({
        x: targetPosition[0],
        y: targetPosition[1],
        z: targetPosition[1],
      });
      if (distance < 0.1) {
        onCollide();
      }
    }
  });

  return (
    <mesh
      ref={ref}
      position={[6, 6, 6]} // Initial top-right position
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: 100657657600,
      }}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="red" />
    </mesh>
  );
}

function AnimationCoin() {
  const [coinPosition, setCoinPosition] = useState([0, 0, 0]);
  const [coinVisible, setCoinVisible] = useState(false);

  const handleClick = () => {
    const x = (Math.random() - 0.5) * 12; // Random position
    const y = (Math.random() - 0.5) * 12;
    const z = 0;
    setCoinPosition([x, y, z]);
    setCoinVisible(true);
  };

  const handleCollide = () => {
    setCoinVisible(false); // Hide coin on collision
    // Reset the moving object to the top-right corner after collision
    // This will reset the position of the moving object after collision
  };

  return (
    <>
      <button
        onClick={handleClick}
        style={{
          position: "absolute",
          top: "100px",
          left: "100px",
          zIndex: 10,
        }}
      >
        Spawn Coin
      </button>
      <Canvas style={{position:"fixed", width: '100vw', height: '100vh' }}>
  {/* Scene Light */}
  <ambientLight intensity={1} />
  <pointLight position={[10, 10, 10]} />
  {/* Coin */}
  {coinVisible && <Coin position={coinPosition} />}
  <MovingObject targetPosition={coinPosition} onCollide={handleCollide} />
</Canvas>
    </>
  );
}

export default AnimationCoin;
