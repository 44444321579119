import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../css/Community.module.css";
import { GoComment } from "react-icons/go";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { flashAction } from "../../../../store/customer/flashSlice";
import {
  addComment,
  addReply,
} from "../../../../store/CommunitySlices/FeedSlice";
import { FaReply } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import { PiShareFatLight } from "react-icons/pi";
import moment from "moment";
import {
  fetchToken,
  sendNotification,
} from "../../../../store/notificationSlice";

const FeedCommentCard = ({ comment, detail = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reply, setReply] = useState("");
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [replyCount, setReplyCount] = useState(comment?.replies?.length);
  const [isLikedComment, setIsLikedComment] = useState(false);
  const [likesComment, setLikesComment] = useState(comment?.likes?.length);
  const [activeTab, setActiveTab] = useState("comment");
  const [error, setError] = useState(null);
  const [posting, setPosting] = useState(false);
  const loginUser = useSelector((state) => state.auth.user);
  const isSignin = useSelector((state) => state.auth.isSignin);
  const { fcmToken } = useSelector((state) => state.notification);
  const userId = comment?.userId?._id;

  useEffect(() => {
    if (userId) {
      dispatch(fetchToken(userId));
    }
  }, [userId, dispatch]);

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!loginUser) {
      setPosting(true);
      dispatch(
        flashAction.addFlash({
          message: "Please log in to comment.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    if (!reply.trim()) return;

    try {
      await dispatch(
        addReply({
          feedId: comment?.feedId,
          content: reply,
          commentId: comment._id,
          userId: loginUser?._id,
        })
      ).unwrap();
      setPosting(false);
      setReply("");
      setReplyCount((prev) => prev + 1);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleLikeComment = async () => {
    if (isSignin) {
      try {
        if (!isLikedComment) {
          setLikesComment(likesComment + 1);
          setIsLikedComment(true);
          if (fcmToken?.user !== loginUser?._id) {
            dispatch(
              sendNotification({
                title: "Like",
                message: ` ${loginUser?.name} Like Your Comment.`,
                token: fcmToken?.token,
                userId: fcmToken?.user,
                type: "community",
                repliesBy: loginUser?._id,
                link: `/community/profile/${loginUser?.userName}`,
              })
            );
          }

          try {
            if (!isLikedComment) {
              setLikesComment(likesComment + 1);
              setIsLikedComment(true);
            } else {
              setLikesComment(likesComment - 1);
              setIsLikedComment(false);
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          setLikesComment(likesComment - 1);
          setIsLikedComment(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
    }
  };

  return (
    <Row>
      <Col xs={2} lg={1} className="mt-3 pt-2 pe-0 overflow-hidden">
        <Link
          to={`/community/profile/${comment?.userId?.userName}`}
          className="text-reset text-decoration-none"
        >
          <Image
            src={comment?.userId?.profileImg?.url}
            roundedCircle
            style={{
              width: "100%",
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
            }}
          />
        </Link>
      </Col>
      <Col xs={10} lg={11}>
        <div className={styles.feedProfileWrapper}>
          <div>
            <Link
              to={`/community/profile/${comment?.userId?.userName}`}
              className="text-reset text-decoration-none"
            >
              <sapn className={styles.feedName}>{comment?.userId?.name}</sapn>
              <sapn>{moment(comment?.createdAt).fromNow()}</sapn>
            </Link>
          </div>
          <Link
            to={`/community/profile/${comment?.userId?.userName}`}
            className="text-reset text-decoration-none"
          >
            <div className={styles.feedUsername}>
              @{comment?.userId?.userName}
            </div>
          </Link>
        </div>

        <Link
          to={`/community/feed/comment/${comment?._id}`}
          className="text-reset text-decoration-none"
        >
          {comment.content} <span className={styles.commentCount}></span>
        </Link>

        <div>
          <div className={styles.commentReactionIconGroup}>
            <span className={styles.reactionIconWrapper}>
              <AiFillHeart
                onClick={handleLikeComment}
                size={18}
                style={{
                  color: isLikedComment ? "red" : "#999",
                  cursor: "pointer",
                  marginRight: "0.3rem",
                }}
              />
              {"  "}
              {likesComment}
            </span>

            <span
              className={styles.reactionIconWrapper}
              onClick={
                detail ? null : () => setShowCommentSection((prev) => !prev)
              }
            >
              <FaReply className={styles.feedCommentIcon} size={18} />
              {replyCount}
            </span>
          </div>
          {showCommentSection && (
            <div className="mt-3">
              <Form
                onSubmit={handleReplySubmit}
                className="d-flex align-items-start"
              >
                <Image
                  src={loginUser?.profileImg?.url}
                  roundedCircle
                  width={40}
                  height={40}
                  className="me-2"
                />
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  placeholder="Write your comment..."
                />
                <Button
                  type="submit"
                  className={styles.commentButton}
                  disabled={posting}
                >
                  {posting ? "Posting..." : "Post"}
                </Button>
              </Form>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default FeedCommentCard;
