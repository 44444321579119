import React, { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { generateToken, onMessageListener } from "../utils/customer/firebase";

const useNotifications = (userId) => {
  const saveTokenToServer = async (newToken, userId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/saveToken`,
        {
          token: newToken,
          userId, 
        }
      );
    } catch (error) {
      console.error("Error saving token to server:", error);
    }
  };

  const removeTokenFromLocalStorage = (userId) => {
    if (userId) {
      localStorage.removeItem("notificationToken");
    }
  };

  useEffect(() => {
 
    if (userId) {
      generateToken().then((newToken) => {
        if (newToken) {
          saveTokenToServer(newToken, userId);
        }
      });
    } else {
     
      const oldToken = localStorage.getItem("notificationToken");
      generateToken().then((newToken) => {
        if (newToken && oldToken !== newToken) {
          localStorage.setItem("notificationToken", newToken); 
          saveTokenToServer(newToken, null); 
        }
      });
    }

    onMessageListener()
      .then((payload) => {
        if (document.visibilityState === "visible") {
          console.log("Foreground notification received:", payload);

          const notificationContent = (
            <div>
              <strong>{payload.notification.title}</strong>:{" "}
              {payload.notification.body}{" "}
              <a
                href={payload.fcmOptions.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                }}
              >
                &nbsp;
              </a>
            </div>
          );

          toast.info(notificationContent, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => console.error("Notification error: ", err));

    return () => {
      if (userId) {
        removeTokenFromLocalStorage(userId);
      }
    };
  }, [userId]); 
};

export default useNotifications;
