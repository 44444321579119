export const formatNumber = (num) => {

    if (Math.abs(num) >= 1.0e12) {
      return (num / 1.0e12)?.toFixed(2) + "T"; 
    } else if (Math.abs(num) >= 1.0e9) {
      return (num / 1.0e9)?.toFixed(2) + "B";
    } else if (Math.abs(num) >= 1.0e6) {
      return (num / 1.0e6)?.toFixed(2) + "M";
    } else {
      return num?.toLocaleString(); 
    }
  };

  export const formateCoinPrice=(price)=>{
    if(price>1){
      return price?.toFixed(2);
    }else if(price<=1 && price>=0.1){
      return price?.toFixed(3)
    }else if(price<=0.1 && price>=0.01){
      return price?.toFixed(4)
    }else if(price<=0.01 && price>=0.001){
      return price.toFixed(5)
    }else if(price<=0.001 && price>=0.0001){
      return price?.toFixed(6)
    }else{
      return price?.toFixed(7)
    }
  }