import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { addCommentToRepost, fetchRepostById, likeRepost } from '../../../../store/CommunitySlices/RepostSlice';
import FeedCard from '../FeedCard';
import styles from "../.././css/Community.module.css";
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import { MdDelete, MdEdit } from 'react-icons/md';
import { flashAction } from '../../../../store/customer/flashSlice';
import { followUser, unfollowUser } from '../../../../store/CommunitySlices/SocialMediaSlice';
import { sendNotification } from '../../../../store/notificationSlice';
import ViewIcon from '../../../../Components/icon/ViewIcon';
import { AiFillHeart } from 'react-icons/ai';
import { GoComment } from 'react-icons/go';
import axios from 'axios';
import { PiShareFatLight } from 'react-icons/pi';
import { FacebookIcon, LinkedinIcon, RedditIcon, TelegramIcon, WhatsappIcon, XIcon } from 'react-share';
import { FiLink } from 'react-icons/fi';
import DeleteFeedRepostModal from '../Common/DeleteFeedRepostModal';
import ShareFeedRepostModal from '../Common/ShareFeedRepostModal';


const RepostCard = ({ repost,detail= false, onDelete = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSignin = useSelector((state) => state.auth.isSignin);
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(repost?.likes?.length);
  const [commentCount,setCommentCount]=useState(repost?.commentCount)
  const [comment, setComment] = useState("");
  const [posting, setPosting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const loginUser = useSelector((state) => state.auth.user);
  const { fcmToken } = useSelector((state) => state.notification);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [showShare,setShowShare]=useState(false);
  const shareUrl = `${process.env.REACT_APP_MAIN_ORIGIN}/community/repost/${repost._id}`;



  useEffect(() => {
    const fetchIsFollowing = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/community/isfollowing/isLikeByUser`,
          {
            params: { userName: repost?.repostedBy?.userName, repostId: repost?._id },

            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setIsLiked(response.data.isLiked);
        setIsFollowing(response.data.isFollowing);
      } catch (err) {
        console.log(err);
      }
    };
    if (loginUser && repost) {
      fetchIsFollowing();
    }
  }, [repost]);
  

  const handleCommentSubmit=async(e)=>{
     e.preventDefault();
        if (!loginUser) {
          setPosting(true);
          dispatch(
            flashAction.addFlash({
              message: "Please log in to comment.",
              type: "Fail",
            })
          );
          navigate("/signin");
          return;
        }
        if (!comment.trim()) return;
    
        try {
          await dispatch(
            addCommentToRepost({
              repostId: repost?._id,
              content: comment,
              userId: loginUser?._id,
            })
          ).unwrap();
          dispatch(
            sendNotification({
              title: "New Comment",
              message: `${loginUser?.name} commented on your post.`,
              token: fcmToken?.token,
              link: `/community/feed/${repost?._id}`,
              userId: loginUser?._id,
              type: "community",
            })
          );
          setPosting(false);
          setComment("");
          setCommentCount(commentCount+1)
          setShowCommentSection(!showCommentSection);
        } catch (error) {
          console.error("Error adding comment:", error);
        }
  }

  const handleFollowToggle = async () => {
    if (!loginUser) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to Follow.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }

    try {
      if (isFollowing) {
        await dispatch(unfollowUser(repost?.createdBy?.userName));
        setIsFollowing(false);
      } else {
        await dispatch(followUser(repost?.createdBy?.userName));
        setIsFollowing(true);

        dispatch(
          sendNotification({
            title: "Follow",
            message: `New Follower arrived.`,
            token: fcmToken?.token,
            link: `/community/profile/${loginUser?.userName}`,
            userId: loginUser?._id,
            type: "community",
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLike=()=>{
    if (isSignin) {
    if (!repost) return;
    dispatch(likeRepost(repost._id));
    isLiked ? setLikes(likes - 1):setLikes(likes + 1)
          setIsLiked(!isLiked);

        } else {
          dispatch(
            flashAction.addFlash({
              message: "Please log in to like or unlike the post.",
              type: "Fail",
            })
          );
          navigate("/signin");
        }
  }

  const handleDeleteConfirmation = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  return (
    <Row>
      <Col xs={2} lg={1} className="mt-4 pt-3 pe-0 overflow-hidden">
        <Link
          to={`/community/profile/${repost?.repostedBy?.userName}`}
          className="text-reset text-decoration-none"
        >
          <Image
            src={repost?.repostedBy?.profileImg?.url}
            roundedCircle
            style={{
              width: "100%",
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
            }}
          />
        </Link>
      </Col>
      <Col xs={10} lg={11}>
      <div className={styles.feedFollowingWrapper}>
        <div className={styles.feedProfileWrapper}>
          <div>
            <Link
              to={`/community/profile/${repost?.repostedBy?.userName}`}
              className="text-reset text-decoration-none"
            >
              <span className={styles.feedName}>{repost?.repostedBy?.name}</span>
            </Link>
            <Link
            to={`/community/profile/${repost?.repostedBy?.userName}`}
            className="text-reset text-decoration-none d-lg-none"
          >
            <div className={styles.feedUsername}>
              @{repost?.repostedBy?.userName}
            </div>
          </Link>
            <span className={styles.feedTimestamp}>
              {moment(repost?.createdAt).fromNow()}
            </span>
          </div>
          <Link
            to={`/community/profile/${repost?.crearepostedBytedBy?.userName}`}
            className="text-reset text-decoration-none d-none d-lg-block"
          >
            <div className={styles.feedUsername}>
              @{repost?.repostedBy?.userName}
            </div>
          </Link>
         
        </div>
      
        <div className={styles.postButtonWrapper}>
          {repost?.repostedBy?.userName === loginUser?.userName ? (
            <>
              <Link
                to={`/community/edit/${repost?._id}`}
                className="text-reset text-decoration-none"
              >
                <MdEdit size={16} />
              </Link>
              <Link
                onClick={handleDeleteConfirmation}
                className="text-reset text-decoration-none"
              >
                <MdDelete />
              </Link>
            </>
          ) : isFollowing ? (
            <></>
          ) : (
            <Button
              variant="primary"
              className={styles.followButton}
              onClick={handleFollowToggle}
            >
              Follow
            </Button>
          )}
        </div>
      </div>
        

<Link to={`/community/repost/${repost?._id}`} className='text-decoration-none text-reset'>
        <div>{repost?.caption}</div>
        </Link>
        <FeedCard feed={repost?.originalPost} detail={true} repost={true} />

        <div>
            <div className={styles.feedReactionIconGroup}>
              <span className={styles.reactionIconWrapper}>
                <ViewIcon className={styles.feedViewIcon} /> {repost?.views}
              </span>
              <span className={styles.reactionIconWrapper}>
                <AiFillHeart
                  onClick={handleLike}
                  size={18}
                  style={{
                    color: isLiked ? "red" : "#999",
                    cursor: "pointer",
                    marginRight: "0.3rem",
                  }}
                />
                {"  "}
                {likes}
              </span>

              <span
                className={styles.reactionIconWrapper}
                onClick={
                  detail ? null : () => setShowCommentSection((prev) => !prev)
                }
              >
                <GoComment className={styles.feedCommentIcon} size={18} />
                {commentCount}
              </span>
              <span className={styles.reactionIconWrapper}>
                <PiShareFatLight className={styles.feedShareICon} onClick={()=>setShowShare(true)} size={24} />
              </span>
            </div>
            {showCommentSection && (
              <div className="mt-3">
                <Form
                  onSubmit={handleCommentSubmit}
                  className="d-flex align-items-start"
                >
                  <Image
                    src={loginUser?.profileImg?.url}
                    roundedCircle
                    width={40}
                    height={40}
                    className="me-2"
                  />
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write your comment..."
                  />
                  <div className={styles.postButtonWrapper}>
                    <Button
                      type="submit"
                      className={styles.postButton}
                      disabled={posting}
                    >
                      {posting ? "Posting..." : "Post"}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>

      </Col>


{/* Share Modal  */}
      <ShareFeedRepostModal setShowShare={setShowShare} showShare={showShare} repost={repost}/>


{/* Delete Modal */}
<DeleteFeedRepostModal onDelete={onDelete} setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} repost={repost} detail={detail}/>


    </Row>
  );
};


export default RepostCard;
