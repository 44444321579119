import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";



export const fetchCoins = createAsyncThunk(
  "globalData/fetchCoins",
  async ({ page, startCount }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/global-data/getCoins`,
      { params: { page, count:startCount } }
    );
    return response.data;
  }
);

export const fetchTicker = createAsyncThunk(
  "globalData/fetchTicker",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/global-data/getTicker`
    );
    return response.data;
  }
);

export const updateExistCoins=createAsyncThunk(
  "globalData/updateExistCoins",
  async ({data}) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/coinList/updateExistCoins`,
      { params: {data } }
    );
    return response.data;
  }
);

const initialState = {
  tickerList: [],
  coinList: { data: [], total: 0, page: 1 },
  tickerstatus: "idle",
  coinListstatus: "idle",
  updateStatus:"idle",
  error: null,
};

const coinSlice = createSlice({
  name: "coinData",
  initialState,
  reducers: {
    updateCoins(state, action) {
      state.coinList = action.payload;

      const newCoins = action.payload;

      newCoins.forEach((newCoin) => {
        const index = state.coinList.findIndex((coin) => coin.id === newCoin.id);
        if (index !== -1) {
          state.coinList[index] = newCoin;
        } else {
          state.coinList.push(newCoin);
        }
      });
    },
    updateTickers(state, action) {
      state.tickerList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicker.pending, (state) => {
        state.tickerstatus = "loading";
      })
      .addCase(fetchTicker.fulfilled, (state, action) => {
        state.tickerstatus = "succeeded";
        state.tickerList = action.payload;
      })
      .addCase(fetchTicker.rejected, (state, action) => {
        state.tickerstatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCoins.pending, (state) => {
        state.coinListstatus = "loading";
      })
      .addCase(fetchCoins.fulfilled, (state, action) => {
        state.coinListstatus = "succeeded";
        state.coinList.data = [...state.coinList.data, ...action.payload.data];
      })
      .addCase(fetchCoins.rejected, (state, action) => {
        state.coinListstatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateExistCoins.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateExistCoins.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.coinList.data = action.payload.data;
      })
      .addCase(updateExistCoins.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action.error.message;
      });
  },
});



export const { updateCoins, updateTickers } = coinSlice.actions;
export const selectCoinList = (state) => state.coinData.coinList;
export const selectTickerList = (state) => state.coinData?.tickerList;

export const selectcoinStatus = (state) => state.coinData?.coinListstatus;
export const selectupdateStatus = (state) => state.coinData?.updateStatus;
export const selectTickerStatus = (state) => state.coinData?.tickerstatus;
export const selectError = (state) => state.coinData.error;

export default coinSlice.reducer;
