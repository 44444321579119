import React, { Suspense, lazy } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Shimmer from "../../Components/shimmers/Shimmer";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import useNotifications from "../../utils/useNotifications";

const Header = lazy(() => import("../../Components/customer/Header"));
const Footer = lazy(() => import("../../Components/customer/Footer"));
const DefaultTitle = lazy(() => import("../../utils/DefaultTitle"));
const ScrollToTop = lazy(() => import("../../utils/customer/ScrollToTop"));

function App() {
  const loginUser = useSelector((state) => state.auth.user);
  const location = useLocation();

  const generateTitle = (path) => {
    if (path === "/") {
      return "IndexByte";
    }

    const segments = path.split("/").filter(Boolean);

    if (segments.length > 0) {
      const lastSegment = segments[segments.length - 1];
      return lastSegment;
    }

    return "Default Title";
  };

  const title = generateTitle(location.pathname);
  useNotifications(loginUser?._id);

  return (
    <Suspense fallback={<Shimmer />}>
      <ScrollToTop />
      <Container className="Container">
        <DefaultTitle title={title} />
        <Header />
        <main>
          <Row>
            <Col className="p-0 px-lg-3">
              <Suspense fallback={<Shimmer />}>
                <Outlet />
              </Suspense>
            </Col>
          </Row>
        </main>
        <Footer />
      </Container>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
